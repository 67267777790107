
import React, {CSSProperties} from "react";
import ActionBarTop from "../rtu-ui/common/action-bar-top";
import { RoutePath } from "../service/routing-service";

export default class TextPage extends React.Component<any, any> {

    blockStyle(): CSSProperties {
        return {margin: "5px", whiteSpace: "break-spaces"}
    }

    render() {
        return (
            <div style={{width: "100%", height: "100%"}}>
                <ActionBarTop title={this.props.title} path={RoutePath.Home}/>
                <div style={this.blockStyle()}>{this.props.text}</div>
            </div>
        );
    }
}
