
import React from "react";
import { ArrowBack, Camera } from "@material-ui/icons";
import { Constants } from "../model/constants";
import { RoutingService } from "../../service/routing-service";

export default class ActionBarTop extends React.Component<any, any> {

    buttonStyle(paddingLeft: boolean) {
        const style: any = { color: "white", height: "52px", width: "30px", zIndex: 100 };
        if (paddingLeft) {
            style.paddingLeft = "10px";
        } else {
            style.paddingRight = "10px";
        }
        return style;
    };

    render() {
        return (
            <div style={Constants.barStyle()}>
                <ArrowBack
                    style={this.buttonStyle(true)}
                    onClick={this.props.onBack ?? this.onBackClick.bind(this)}
                />

                <div style={Constants.barText()}>{this.props.title}</div>

                {this.props.onCameraSwitch && <Camera
                    style={this.buttonStyle(false)}
                    onClick={this.props.onCameraSwitch}
                />}
            </div>
        );
    }

    onBackClick() {
        if (this.props?.path) {
            RoutingService.instance.goTo(this.props.path);
        } else {
            RoutingService.instance.back()
        }
    }
}
