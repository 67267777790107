
import { TextDataScannerResult } from "scanbot-web-sdk/@types";
import { RoutingService } from "../service/routing-service";
import { ScanbotSdkService } from "../service/scanbot-sdk-service";
import BaseScannerComponent from "./common/base-scanner-component";
import { AnimationType } from "./enum/animation-type";
import { isMobile } from 'react-device-detect';

export default class TextDataScannerComponent extends BaseScannerComponent {
    componentDidMount() {
        this.push(AnimationType.PushRight);
    }

    render() {
        return this.controller(ScanbotSdkService.TEXT_DATA_SCANNER_CONTAINER, "Text Data Scanner", '', this.onCameraSwitch);
    }

    onCameraSwitch() {
        ScanbotSdkService.instance.textDataScanner.swapCameraFacing(isMobile);
    }

    onTextDetected(result: TextDataScannerResult) {
        this.props.onTextDetected(result);
    }

    onError(e: Error) {
        this.props.onError(e);
    }

    async push(type: AnimationType) {
        super.push(type);
        this.pushType = type;
        this.updateAnimationType(type, async () => {
            await ScanbotSdkService.instance.createTextDataScanner(this.onTextDetected.bind(this), this.onError.bind(this));
        });
    }

    onDonePress() {
        this.pop();
    }

    pop() {
        super.pop();
        this.updateAnimationType(AnimationType.Pop, () => {
            ScanbotSdkService.instance.disposeTextDataScanner();
        });

        RoutingService.instance.back();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        ScanbotSdkService.instance.disposeTextDataScanner();
    }
}
