import { Barcode } from "scanbot-web-sdk/@types";

export default class Barcodes {

    public static instance = new Barcodes();

    private list: Barcode[] = [];

    count() {
        return this.list.length;
    }

    public add(code: Barcode) {
        this.list.push(code);
    }

    public addAll(codes: Barcode[]) {
        codes.forEach((code: Barcode) => {
            // Prevent adding the same barcode in the list
            if (!this.isBarcodeAlreadyAdded(code)) {
                this.add(code);
            }
        })
    }

    public static format(codes: Barcode[]): string {
        return JSON.stringify(codes.map((code: Barcode) => code.text + " (" + code.format + ") "));
    }

    private isBarcodeAlreadyAdded(barcode: Barcode) {
        if (barcode && barcode.rawBytes) {
            for (let i = 0; i < this.list.length; i++) {
                if (Buffer.from(this.list[i].rawBytes, 0).equals(Buffer.from(barcode.rawBytes, 0))) {
                    return true;
                }
            };
        }
        
        return false;
    }
}
