export class ViewUtils {

    private static preventDefaultCallback(event: any){
        event.preventDefault();
    }
    
    public static disableScreenMovement(): void {
        document.addEventListener("touchmove", ViewUtils.preventDefaultCallback, { passive: false });
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        document.documentElement.style.overflow = 'hidden';
    }

    public static enableScreenMovement(): void {
        document.removeEventListener("touchmove", ViewUtils.preventDefaultCallback);
        document.documentElement.style.overflow = 'auto';
    }
}