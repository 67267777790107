
import { ScanbotSdkService } from "../service/scanbot-sdk-service";
import { DocumentDetectionResult } from "scanbot-web-sdk/@types";
import BaseScannerComponent from "./common/base-scanner-component";
import { AnimationType } from "./enum/animation-type";
import Pages from "../model/pages";
import { RoutePath, RoutingService } from "../service/routing-service";
import { isMobile } from 'react-device-detect';

export default class DocumentScannerComponent extends BaseScannerComponent {
    componentDidMount() {
        this.push(AnimationType.PushRight);
    }

    render() {
        return this.controller(ScanbotSdkService.DOCUMENT_SCANNER_CONTAINER, "Document Scanner", this.labelText(), this.onCameraSwitch);
    }

    onCameraSwitch() {
        ScanbotSdkService.instance.documentScanner.swapCameraFacing(isMobile);
    }

    onDocumentDetected(result: DocumentDetectionResult) {
        this.props.onDocumentDetected(result);
        document.getElementById("count-label")!.innerHTML = this.labelText();
    }

    labelText() {
        return Pages.instance.count() + " Pages";
    }

    onError(e: Error) {
        this.props.onError(e);
    }

    async push(type: AnimationType) {
        super.push(type);
        this.pushType = type;
        this.updateAnimationType(type, async () => {
            await ScanbotSdkService.instance.createDocumentScanner(this.onDocumentDetected.bind(this), this.onError.bind(this));
        });
    }

    disposeDocumentScanner() {
        this.updateAnimationType(AnimationType.Pop, () => {
            ScanbotSdkService.instance.disposeDocumentScanner();
        });
    }

    onDonePress() {
        super.onDonePress();

        if (Pages.instance.count() > 0) {
            this.disposeDocumentScanner();
            RoutingService.instance.replaceTo(RoutePath.ViewDocuments);
        } else {
            this.pop();
        }
    }

    pop() {
        super.pop();
        this.disposeDocumentScanner();

        RoutingService.instance.back();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        ScanbotSdkService.instance.disposeDocumentScanner();
    }
}
