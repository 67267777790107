
import React from "react";
import { CircularProgress } from "@material-ui/core";

export default class LoadingScreen extends React.Component<any, any> {

    render() {

        if (!this.props.isVisible) {
            return null;
        }

        return (
            <div className={'cover'}>
                <div style={{ transform: "translate(45%, 500%)" }}>
                    <CircularProgress />
                </div>
            </div>
        );
    }
}
