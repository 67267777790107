
import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class Toast extends React.Component<any, any> {
    render() {
        const autoHideDuration: number = this.props?.alert?.autoClose === false ? null : (this.props.alert?.durationMillis ?? 2000);

        return (
            <Snackbar
                open={!!this.props.alert}
                autoHideDuration={autoHideDuration}
                onClose={this.props.onClose}>
                <Alert
                    onClose={this.props.onClose}
                    severity={this.props.alert?.color}>
                    <small className='toast-style'>
                        {this.props.alert?.text}
                    </small>
                </Alert>
            </Snackbar>
        );
    }
}
