
// Import SDK from webpack directory to ensure web assembly binary and worker and bundled with webpack
import ScanbotSDK from "scanbot-web-sdk/webpack";

// Other typings should be imported from @types
import {
    DocumentScannerConfiguration,
    IDocumentScannerHandle,
    CroppingViewConfiguration,
    ICroppingViewHandle,
    BarcodeScannerConfiguration,
    IBarcodeScannerHandle,
    BinarizationFilter,
    ColorFilter,
    ImageFilter,
    TiffGenerationOptions,
    PdfGenerationOptions,
    TiffGenerator,
    PdfGenerator,
    ITextDataScannerHandle,
    TextDataScannerConfiguration,
} from "scanbot-web-sdk/@types";

import Pages from "../model/pages";
import DetailedImageFilter from "../model/detailed-image-filter";
import { BarcodeFormat } from "scanbot-web-sdk/@types/model/barcode/barcode-format";
import { IMrzScannerHandle } from "scanbot-web-sdk/@types/interfaces/i-mrz-scanner-handle";

// eslint-disable-next-line import/no-webpack-loader-syntax
require("!!file-loader?outputPath=tessdata&name=[name].[ext]!scanbot-web-sdk/bundle/bin/complete/tessdata/eng.traineddata");
// eslint-disable-next-line import/no-webpack-loader-syntax
require("!!file-loader?outputPath=tessdata&name=[name].[ext]!scanbot-web-sdk/bundle/bin/complete/tessdata/deu.traineddata");


export class ScanbotSdkService {

    static DOCUMENT_SCANNER_CONTAINER = "document-scanner-view";
    static CROPPING_VIEW_CONTAINER = "cropping-view";
    static BARCODE_SCANNER_CONTAINER = "barcode-scanner-view";
    static MRZ_SCANNER_CONTAINER = "mrz-scanner-view";
    static TEXT_DATA_SCANNER_CONTAINER = "text-data-scanner-view";

    public static instance = new ScanbotSdkService();

    // Set license according to the flavor
    license = "c4yMPFcdApjsa4bksRoqNn+zQ9+3Ia" +
        "e/4E6ntOB+X75M4x7dzGQN4rCjxwhH" +
        "/Ew6tczHh5gc6KLSOYp40mGkfi20Cx" +
        "aHMP67V/64dH49s+XFikblWiiEGJim" +
        "IFEC2TGhXinzlFKyrHXAMDkZwtFY4K" +
        "NUPujCrslSRDqX9tvK6maaHv0bS4pj" +
        "G6kaDY9/EhhYS28NjBEqVHm/FWuuxs" +
        "42FHRPftL9qSrLHC1lgCVVJBXcVWnw" +
        "YoTCftZWc9LaquCq5DSazuNdNE7Jt+" +
        "O9m5DJOmbtUlPnJ2q6hrMjEqeFjUIu" +
        "zCRQFaCH80dhCNODIJDQdTEUhcvwDM" +
        "3mQteM/xIqow==\nU2NhbmJvdFNESw" +
        "p3ZWJzZGstZGVtby5zY2FuYm90Lmlv" +
        "fHdlYnNkay1kZW1vLWludGVybmFsLn" +
        "NjYW5ib3QuaW98d2Vic2RrLXRlc3Qu" +
        "c2NhbmJvdC5pb3xsb2NhbGhvc3QKMT" +
        "cyNzgyNzE5OQo4Mzg4NjA3Cjg=\n";

    sdk?: ScanbotSDK;

    documentScanner?: IDocumentScannerHandle;
    barcodeScanner?: IBarcodeScannerHandle;
    croppingView?: ICroppingViewHandle;
    mrzScanner?: IMrzScannerHandle;
    textDataScanner?: ITextDataScannerHandle;

    public async initialize() {
        this.sdk = await ScanbotSDK.initialize({ licenseKey: this.license, engine: "/" });
        return this.sdk;
    }

    async setLicenseFailureHandler(callback: any) {
        await this.setLicenseTimeout(callback);
    }

    private async setLicenseTimeout(callback: any) {
        // Scanbot WebSDK does not offer real-time license failure handler. Simply loop to check it manually
        const info = await this.sdk?.getLicenseInfo();
        if (info && info.status !== "Trial" && info.status !== "Okay") {
            callback(info.description);
        } else {
            setTimeout(() => {
                this.setLicenseTimeout(callback);
            }, 2000);
        }

    }
    public async isLicenseValid(): Promise<boolean> {
        const info = await this.sdk?.getLicenseInfo();
        if (!info) {
            return false;
        }
        return info.status === "Trial" || info.status === "Okay";
    }

    public async createDocumentScanner(detectionCallback: any, onError: (e: Error) => void) {
        const config: DocumentScannerConfiguration = {
            onDocumentDetected: detectionCallback,
            containerId: ScanbotSdkService.DOCUMENT_SCANNER_CONTAINER,
            videoConstraints: {
                width: {
                    ideal: 1920
                },
                height: {
                    ideal: 1080
                },
            },
            onError: onError
        };

        if (this.sdk) {
            try {
                this.documentScanner = await this.sdk!.createDocumentScanner(config);
            } catch (e) {
                onError(e as Error);
            }
        }
    }

    public disposeDocumentScanner() {
        this.documentScanner?.dispose();
    }

    public async createBarcodeScanner(callback: any, onError: (e: Error) => void) {
        const barcodeFormats: BarcodeFormat[] = [
            "AZTEC",
            "CODABAR",
            "CODE_39",
            "CODE_93",
            "CODE_128",
            "DATA_MATRIX",
            "EAN_8",
            "EAN_13",
            "ITF",
            "MAXICODE",
            "PDF_417",
            "QR_CODE",
            "MICRO_QR_CODE",
            "RSS_14",
            "RSS_EXPANDED",
            "UPC_A",
            "UPC_E",
            "UPC_EAN_EXTENSION",
            "MSI_PLESSEY",
            "IATA_2_OF_5",
            "INDUSTRIAL_2_OF_5",
            "CODE_25"
        ];

        const config: BarcodeScannerConfiguration = {
            containerId: ScanbotSdkService.BARCODE_SCANNER_CONTAINER,
            captureDelay: 100,
            onBarcodesDetected: callback,
            barcodeFormats: barcodeFormats,
            videoConstraints: {
                width: {
                    ideal: 1920
                },
                height: {
                    ideal: 1080
                },
            },
            onError: onError
        };
        if (this.sdk) {
            try {
                this.barcodeScanner = await this.sdk!.createBarcodeScanner(config);
            } catch (e) {
                onError(e as Error);
            }
        }
    }

    public disposeBarcodeScanner() {
        this.barcodeScanner?.dispose();
    }

    public async createMrzScanner(onMrzDetected: any, onError: any) {
        const config = {
            containerId: ScanbotSdkService.MRZ_SCANNER_CONTAINER,
            videoConstraints: {
                width: {
                    ideal: 1920
                },
                height: {
                    ideal: 1080
                },
            },
            onMrzDetected: onMrzDetected,
            onError: onError
        };

        if (this.sdk) {
            try {
                this.mrzScanner = await this.sdk!.createMrzScanner(config);
            } catch (e) {
                onError(e as Error);
            }
        }
    }

    public disposeMrzScanner() {
        this.mrzScanner?.dispose();
    }

    public async createTextDataScanner(onTextDetected: any, onError: any) {
        const config: TextDataScannerConfiguration = {
            containerId: ScanbotSdkService.TEXT_DATA_SCANNER_CONTAINER,
            videoConstraints: {
                width: {
                    ideal: 1920
                },
                height: {
                    ideal: 1080
                },
            },
            onTextDetected: onTextDetected,
            onError: onError,
            ocrResolutionLimit: 400,
            supportedLanguages: ['eng', 'deu']
        };

        if (this.sdk) {
            try {
                this.textDataScanner = await this.sdk!.createTextDataScanner(config);
            } catch (e) {
                onError(e as Error);
            }
        }
    }

    public disposeTextDataScanner() {
        this.textDataScanner?.dispose();
    }

    public async openCroppingView(page: any) {
        const configuration: CroppingViewConfiguration = {
            containerId: ScanbotSdkService.CROPPING_VIEW_CONTAINER,
            image: page.original,
            polygon: page.polygon,
            rotations: page.rotations ?? 0
        };

        this.croppingView = await this.sdk!.openCroppingView(configuration);
    }

    public disposeCroppingView() {
        this.croppingView?.dispose();
    }

    public binarizationFilters(): BinarizationFilter[] {
        return [
            'binarized',
            'otsuBinarization',
            'pureBinarized',
            'lowLightBinarization',
            'lowLightBinarization2',
            'deepBinarization'
        ];
    }

    public colorFilters(): ColorFilter[] {
        return [
            'color',
            'gray',
            'colorDocument',
            'blackAndWhite',
            'edgeHighlight'
        ];
    }

    public availableFiltersDetailed(): DetailedImageFilter[] {
        let detailedFilters: DetailedImageFilter[] = [];

        let filters = this.availableFilters();

        if (filters) {
            filters.forEach(filter => {
                detailedFilters.push(new DetailedImageFilter(filter as ColorFilter | BinarizationFilter | "none", null))
            });
        }

        return detailedFilters;
    }

    public availableFilters(): string[] {
        return ["none"].concat(this.binarizationFilters()).concat(this.colorFilters());
    }
    filterByIndex(value: string) {
        return this.availableFilters()[parseInt(value)];
    }

    public async applyFilter(image: ArrayBuffer, filter: ImageFilter) {
        return await this.sdk!.applyFilter(image, filter);
    }

    async documentImageAsBase64(index: number) {
        const bytes = Pages.instance.imageAtIndex(index);
        if (bytes) {
            return await this.sdk!.toDataUrl(bytes);
        }
    }

    async reapplyFilter() {
        const existing = Pages.instance.getActiveItem();
        if (!existing.filter) {
            return;
        }
        existing.filtered = await this.applyFilter(existing.cropped, existing.filter.name);
    }

    async generatePDF(pages: any[]) {
        const options: PdfGenerationOptions = { standardPaperSize: "A4", landscape: true };
        const generator: PdfGenerator = await this.sdk!.beginPdf(options);
        for (const page of pages) {
            let image = page.filtered ?? page.cropped ?? page.original;
            await generator.addPage(image);
        }
        return await generator.complete();
    }

    async generateTIFF(pages: any[]) {
        const options: TiffGenerationOptions = { binarizationFilter: "deepBinarization", dpi: 123 };
        const generator: TiffGenerator = await this.sdk!.beginTiff(options);
        for (const page of pages) {
            await generator.addPage(page.cropped ?? page.original);
        }
        return await generator.complete();
    }
}
