
import { MrzResult } from "scanbot-web-sdk/@types/model/mrz/mrz-result";
import { RoutingService } from "../service/routing-service";
import { ScanbotSdkService } from "../service/scanbot-sdk-service";
import BaseScannerComponent from "./common/base-scanner-component";
import { AnimationType } from "./enum/animation-type";
import { isMobile } from 'react-device-detect';

export default class MrzScannerComponent extends BaseScannerComponent {
    componentDidMount() {
        this.push(AnimationType.PushRight);
    }

    render() {
        return this.controller(ScanbotSdkService.MRZ_SCANNER_CONTAINER, "MRZ Scanner", '', this.onCameraSwitch);
    }

    onCameraSwitch() {
        ScanbotSdkService.instance.mrzScanner.swapCameraFacing(isMobile);
    }

    onMrzsDetected(result: MrzResult) {
        this.props.onMrzsDetected(result);
    }

    onError(e: Error) {
        this.props.onError(e);
    }

    async push(type: AnimationType) {
        super.push(type);
        this.pushType = type;
        this.updateAnimationType(type, async () => {
            await ScanbotSdkService.instance.createMrzScanner(this.onMrzsDetected.bind(this), this.onError.bind(this));
        });
    }

    onDonePress() {
        this.pop();
    }

    pop() {
        super.pop();
        this.updateAnimationType(AnimationType.Pop, () => {
            ScanbotSdkService.instance.disposeMrzScanner();
        });

        RoutingService.instance.back();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        ScanbotSdkService.instance.disposeMrzScanner();
    }
}
