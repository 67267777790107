
import React, { CSSProperties } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import DetailedImageFilter from "../model/detailed-image-filter";
import StringUtils from "../utils/string-utils";
import './subviews-styles.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class FilterDialog extends React.Component<any, any> {

    render() {
        return (
            // @ts-ignore
            <Dialog className={'component-filter-dialog'} fullScreen open={this.props.data.visible} onClose={this.props.onClose} TransitionComponent={Transition}>
                <AppBar className={'app-bar'} style={{position: 'relative'} as CSSProperties}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.props.onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography className={'title'} variant="h6">
                            Filter
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => this.props.onApply(this.findSelected())}>
                            APPLY
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    {this.renderFilters()}
                </List>
            </Dialog>
        );
    }

    hasSelectedFilter(): boolean {
        return !!this.findSelected();
    }
    findSelected() {
        const result = this.props.data.filters.find((filter: DetailedImageFilter) => filter.selected);
        return result;
    }

    setActiveFilter(filters: DetailedImageFilter[], currentFilterName: string) {
        filters.some((filter: DetailedImageFilter) => {
            if (filter.name === currentFilterName) {
                filter.selected = true;
                return true;
            }
            return false;
        });
    }

    renderFilters() {
        const { filters, currentFilter } = this.props.data;
        if (!filters) {
            return null;
        }

        if (!this.hasSelectedFilter()) {
            if (currentFilter) {
                this.setActiveFilter(filters, currentFilter.name);
            } else {
                this.setActiveFilter(filters, "none")
            }
        }

        return filters.map((filter: DetailedImageFilter) => this.renderFilter(filter));
    }

    renderFilter(filter: DetailedImageFilter) {

        return (
            <ListItem key={filter.name} button selected={filter.selected} onClick={() => {
                this.props.data.filters.forEach((filter: DetailedImageFilter) => {
                    filter.selected = false;
                });
                filter.selected = true;
                this.setState({});
            }}>
                <ListItemText
                    primary={StringUtils.capitalize(filter.name)}
                    secondary={filter.description}
                />
            </ListItem>
        );
    }

}
