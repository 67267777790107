import * as React from "react";
import { ScanbotSdkService } from "../service/scanbot-sdk-service";
import { ImageFilter } from "scanbot-web-sdk/@types";
import LoadingScreen from "../subviews/loading-screen";
import Header from "./main-menu/header";
import { RoutingService } from "../service/routing-service";
import { ImageUtils } from "../utils/image-utils";

export interface StandaloneFilteringPageState {
    image: ArrayBuffer,
    imageOrg: ArrayBuffer,
    isLoading: boolean,
    selectedFilter: string
}

export class StandaloneFilteringPage extends React.Component<any, StandaloneFilteringPageState> {
    private service: ScanbotSdkService;
    private availableFilters: string[];

    constructor(props: any) {
        super(props)

        this.service = ScanbotSdkService.instance;
        this.availableFilters = this.service.availableFilters();

        this.state = {
            imageOrg: null,
            image: null,
            isLoading: false,
            selectedFilter: 'none'
        }
    }

    render() {
        const filterSelector =
            this.state.image ?
                <div>
                    Filter:
                    <select
                        onChange={
                            (event) => {
                                this.onFilterChanged(event.target.value);
                            }
                        }
                        value={this.state.selectedFilter}>
                        {
                            this.availableFilters.map((filter, index) => {
                                return <option>{filter}</option>;
                            })
                        }
                    </select>
                </div>
                :
                <br />;

        const image = this.state.image ? <div>
            <LoadingScreen isVisible={this.state.isLoading} />
            <img className="image" src={URL.createObjectURL(new Blob([this.state.image]))} alt={""} />
        </div> : <br />;

        const render =
            <div className="component-standalone-filtering">
                <div onClick={this.onHeaderClick.bind(this)}>
                    <Header back={false} />
                </div>
                <div className="content">
                    < p > Please choose a JPEG file:</p >
                    <input
                        className="file-picker"
                        type="file"
                        accept="image/jpeg"
                        onChange={
                            (e) => {
                                e.preventDefault();
                                let reader = new FileReader();
                                // @ts-ignore
                                let file = e.target.files[0];
                                reader.readAsArrayBuffer(file);

                                reader.onload = async (e) => {
                                    const downsizedImage = await ImageUtils.downscale(ScanbotSdkService.instance.sdk, new Uint8Array(reader.result as ArrayBuffer), 500);
                                    // @ts-ignore
                                    this.onImageChanged(downsizedImage);
                                };
                            }
                        }
                    />
                    <br />
                    {filterSelector}
                    {image}
                    <small> *We do not upload your selected photo(s) - the image processing takes place solely on your local device. </small>
                </div >
            </div >;

        return render;
    }

    onHeaderClick() {
        RoutingService.instance.home();
    }

    onFilterChanged(filter: string) {
        if (filter) {
            this.setState({
                isLoading: true,
            });

            if ('none' !== filter) {
                const filtered: Promise<ArrayBuffer> = this.service.applyFilter(this.state.imageOrg, filter as ImageFilter);
                filtered.then((imageBuffer) => {
                    this.setState({
                        image: imageBuffer,
                        isLoading: false,
                        selectedFilter: filter
                    });
                })
            } else {
                this.setState({
                    image: this.state.imageOrg,
                    isLoading: false
                })
            }
        }
    }

    onImageChanged(image: ArrayBuffer) {
        this.setState(
            {
                imageOrg: image,
                image: image,
                selectedFilter: 'none'
            }
        )
    }

    async componentDidMount() {
        await ScanbotSdkService.instance.initialize();
        RoutingService.initialize(this.props.history);
        RoutingService.instance.reset();
    }
}