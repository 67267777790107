export default class OnboardingModel {
	public static TEXT: any = {
		en: {
			title: {
				0: 'Welcome to Scanbot SDK',
				1: 'Optimize your workflow',
				2: 'State-of-the-art technology',
				3: 'We protect your data',
			},
			description: {
				0:
					'A tailored digitization solution for your business to improve operational efficiency, ' +
					'gain significant cost savings, and boost customer as well as employee satisfaction.',
				1: 'With the Scanbot Scanner SDK, your employees and customers can scan documents right from their smartphone.',
				2: 'Our intelligent solutions use the latest Machine Learning algorithms to ensure near-perfect results.',
				3: 'Our ML-based solutions offer fast and powerful, image, text, and data capture capabilities while keeping your data safely encrypted.',
			},
			buttonText: {
				0: 'Skip',
				1: 'Try now',
			},
		},
		de: {
			title: {
				0: 'Willkommen bei Scanbot SDK',
				1: 'Workflow-Optimierung ',
				2: 'Hochmoderne Technologie',
				3: 'Wir schützen Ihre Daten',
			},
			description: {
				0:
					'Eine maßgeschneiderte Digitalisierungslösung für Ihr Unternehmen. ' +
					'Verbessern Sie die betriebliche Effizienz, während Sie massive Kosteneinsparungen erzielen ' +
					'und die Kunden- sowie Mitarbeiterzufriedenheit steigern.',
				1: 'Mit dem Scanbot Scanner SDK können Ihre Mitarbeiter und Kunden Dokumente direkt von ihrem Smartphone aus scannen.',
				2:
					'Unsere intelligenten Lösungen nutzen die neuesten Algorithmen des Machine-Learning, ' +
					'um nahezu perfekte Ergebnisse zu gewährleisten.',
				3:
					'Unsere ML-basierten Lösungen bieten schnelle und leistungsstarke Funktionen zur Bild-, Text- ' +
					'und Datenerfassung und halten Ihre Daten sicher verschlüsselt.',
			},
			buttonText: {
				0: 'Überspringen',
				1: 'Testen',
			},
		},
	};
}
