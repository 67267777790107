import React from 'react';

import { BarcodeResult, TextDataScannerResult } from 'scanbot-web-sdk/@types';

import ImageResultsPage from './pages/image-results-page';
import ImageDetailPage from './pages/image-detail-page';
import CroppingPage from './pages/cropping-page';

import Pages from './model/pages';
import { ScanbotSdkService } from './service/scanbot-sdk-service';
import { RoutePath, RoutingService } from './service/routing-service';

import DocumentScannerComponent from './rtu-ui/document-scanner-component';
import BarcodeScannerComponent from './rtu-ui/barcode-scanner-component';
import Barcodes from './model/barcodes';
import Onboarding from './pages/onboarding/onboarding-carousel';
import { StorageService } from './service/storage-service';

import MainMenu from './pages/main-menu/main-menu';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import TextPage from './pages/text-page';
import FileLoader from './utils/file-loader';
import { Toast } from './subviews/toast';
import MrzScannerComponent from './rtu-ui/mrz-scanner-component';
import { MrzField, MrzResult } from 'scanbot-web-sdk/@types/model/mrz/mrz-result';
import LoadingScreen from "./subviews/loading-screen";
import TextDataScannerComponent from './rtu-ui/text-data-scanner-component';

class App extends React.Component<any, any> {

	acknowledgements?: string;

	constructor(props: any) {
		super(props);

		RoutingService.initialize(this.props.history);

		RoutingService.instance.observeChanges(() => {
			this.forceUpdate();
		});

		this.state = {
			alert: undefined,
			activeImage: undefined,
			sdk: undefined,
			error: {
				message: undefined,
			},
			version: {
				app: '0',
				sdk: '0',
			},
			loading: true,
			language: this.languageOrDefault(),
		};
	}

	async componentDidMount() {
		const sdk = await ScanbotSdkService.instance.initialize();

		this.setState({ sdk: sdk, loading: false });

		await ScanbotSdkService.instance.setLicenseFailureHandler((error: any) => {
			RoutingService.instance.reset();
			this.setState({ error: { message: error } });
			if (this._documentScanner?.isVisible()) {
				this._documentScanner?.pop();
			}
			if (this._barcodeScanner?.isVisible()) {
				this._barcodeScanner?.pop();
			}
			if (this._mrzScanner?.isVisible()) {
				this._mrzScanner?.pop();
			}
			if (this._textDataScanner?.isVisible()) {
				this._textDataScanner?.pop();
			}
		});

		this.acknowledgements = await FileLoader.load('/assets/Libraries.txt');
		this.setState({
			version: {
				app: await FileLoader.loadVersionInfo(),
				sdk: sdk.version,
			},
		});
	}

	languageOrDefault(): string {
		const split = window.location.href.split('?lang=');
		if (split.length > 1) {
			return split[1];
		}
		return 'en';
	}

	render() {
		const showOnboarding = !StorageService.instance.getHasVisited();

		const mainMenuProps = {
			language: this.state.language,
			pageCount: Pages.instance.count(),
			version: this.state.version,
			callDocument: () => {
				RoutingService.instance.goTo(RoutePath.DocumentScanner);
			},
			callBarcode: () => {
				RoutingService.instance.goTo(RoutePath.BarcodeScanner);
			},
			callMrz: () => {
				RoutingService.instance.goTo(RoutePath.MrzScanner);
			},
			callTextData: () => {
				RoutingService.instance.goTo(RoutePath.TextDataScanner);
			},
			viewDocuments: () =>
				RoutingService.instance.goTo(RoutePath.ViewDocuments),
			viewAcknowledgements: () => {
				RoutingService.instance.goTo(RoutePath.Acknowledgements);
			},
		};

		if (
			(Pages.instance.isEmpty() &&
				RoutingService.exists() &&
				RoutingService.instance.isAtImageResult())
			||
			(!this.state.sdk &&
				RoutingService.instance.isScanner())
		) {
			RoutingService.instance.replaceTo(RoutePath.Home);
			this.setState({});
			return <div />;
		}

		return (
			<>
				<LoadingScreen isVisible={this.state.loading} />

				<Toast alert={this.state.alert} onClose={() => this.setState({ alert: undefined })} />

				<Switch>
					<Route path={RoutePath.Onboarding}>
						<Onboarding
							language={this.state.language}
						/>
					</Route>
					<Route exact path={RoutePath.Home}>
						{showOnboarding ? (
							<Redirect to={RoutePath.Onboarding} />
						) : (
							<MainMenu {...mainMenuProps} />
						)}
					</Route>
					<Route exact path={RoutePath.ViewDocuments}>
						<ImageResultsPage sdk={this.state.sdk} />
					</Route>
					<Route exact path={RoutePath.ViewDocument}>
						<ImageDetailPage image={this.state.activeImage} />
					</Route>
					<Route path={RoutePath.CropDocument}>
						<CroppingPage sdk={this.state.sdk} />
					</Route>
					<Route path={RoutePath.DocumentScanner}>
						{this.documentScanner()}
					</Route>
					<Route path={RoutePath.BarcodeScanner}>
						{this.barcodeScanner()}
					</Route>
					<Route path={RoutePath.MrzScanner}>
						{this.mrzScanner()}
					</Route>
					<Route path={RoutePath.TextDataScanner}>
						{this.textDataScanner()}
					</Route>
					<Route path={RoutePath.Acknowledgements}>
						<TextPage title={'Acknowledgements'} text={this.acknowledgements} />
					</Route>
				</Switch>
			</>
		);
	}

	_documentScannerHtmlComponent: any;
	_documentScanner?: DocumentScannerComponent | null;
	documentScanner() {
		if (!this._documentScannerHtmlComponent) {
			this._documentScannerHtmlComponent = (
				<DocumentScannerComponent
					ref={(ref) => (this._documentScanner = ref)}
					sdk={this.state.sdk}
					onDocumentDetected={this.onDocumentDetected.bind(this)}
					onError={(e: Error) => {
						alert(e.name + ': ' + e.message);
						this._documentScanner.pop();
					}}
				/>
			);
		}
		return this._documentScannerHtmlComponent;
	}

	_barcodeScannerHtmlComponent: any;
	_barcodeScanner?: BarcodeScannerComponent | null;
	barcodeScanner() {
		if (!this._barcodeScannerHtmlComponent) {
			this._barcodeScannerHtmlComponent = (
				<BarcodeScannerComponent
					ref={(ref) => (this._barcodeScanner = ref)}
					sdk={this.state.sdk}
					onBarcodesDetected={this.onBarcodesDetected.bind(this)}
					onError={(e: Error) => {
						alert(e.name + ': ' + e.message);
						this._barcodeScanner.pop();
					}}
				/>
			);
		}
		return this._barcodeScannerHtmlComponent;
	}

	_mrzScannerHtmlComponent: any;
	_mrzScanner?: MrzScannerComponent | null;
	mrzScanner() {
		if (!this._mrzScannerHtmlComponent) {
			this._mrzScannerHtmlComponent = (
				<MrzScannerComponent
					ref={ref => this._mrzScanner = ref}
					sdk={this.state.sdk}
					onMrzsDetected={this.onMrzDetected.bind(this)}
					onError={(e: Error) => {
						alert(e.name + ': ' + e.message);
						this._mrzScanner.pop();
					}}
				/>
			);
		}
		return this._mrzScannerHtmlComponent;
	}

	_textDataScannerHtmlComponent: any;
	_textDataScanner?: TextDataScannerComponent | null;
	textDataScanner() {
		if (!this._textDataScannerHtmlComponent) {
			this._textDataScannerHtmlComponent = (
				<TextDataScannerComponent
					ref={ref => this._textDataScanner = ref}
					sdk={this.state.sdk}
					onTextDetected={this.onTextDetected.bind(this)}
					onError={(e: Error) => {
						alert(e.name + ': ' + e.message);
						this._textDataScanner.pop();
					}}
				/>
			);
		}
		return this._textDataScannerHtmlComponent;
	}

	async onDocumentDetected(result: any) {
		ScanbotSdkService.instance.sdk?.utils.flash();
		Pages.instance.add(result);
		this.setState({});
	}

	async onBarcodesDetected(result: BarcodeResult) {
		Barcodes.instance.addAll(result.barcodes);
		// If you have any additional processing to do, consider pausing
		// the scanner here, else you might (will) receive multiple results:
		// ScanbotSdkService.instance.barcodeScanner?.pauseDetection();
		this.setState({
			alert: {
				color: 'success',
				text: Barcodes.format(result.barcodes),
				autoClose: true,
			},
		});
	}

	async onMrzDetected(mrz: MrzResult) {
		ScanbotSdkService.instance.mrzScanner?.pauseDetection();

		const validateAndPrepare = (fieldTitle: string, field: MrzField) => {
			if (!field) {
				return '';
			}

			return fieldTitle + ': ' + field.value + ` (${Number(field.confidence).toFixed(3)}) \n`;
		}

		let text = '';
		if (mrz) {
			text += validateAndPrepare('Document Type', mrz.documentType);
			text += validateAndPrepare('First Name', mrz.givenNames);
			text += validateAndPrepare('Last Name', mrz.surname);
			text += validateAndPrepare('Issuing Authority', mrz.issuingAuthority);
			text += validateAndPrepare('Nationality', mrz.nationality);
			text += validateAndPrepare('Birth Date', mrz.birthDate);
			text += validateAndPrepare('Gender', mrz.gender);
			text += validateAndPrepare('Date of Expiry', mrz.expiryDate);

			this.setState({
				alert: {
					color: 'success',
					text: text,
					durationMillis: 5000,
				},
			});

			setTimeout(() => { ScanbotSdkService.instance.mrzScanner?.resumeDetection() }, 1000);
		}
	}

	async onTextDetected(textData: TextDataScannerResult) {
		const clearText = textData?.text?.replace(new RegExp('[\\n ]+'), '');
		if (!clearText) return;

		if (textData.confidence > 0.6) {
			// TODO confidence is bugged in v3.0.0. Re-introduce confidence check later
			// return;
		}
		var text = `Text: ${textData.text.trim()} \nconfidence: ${textData.confidence} \nisValidated: ${textData.validated}`;

		if (textData.validated) {
			ScanbotSdkService.instance.textDataScanner?.pauseDetection();
			alert(textData.text.trim());
			this.setState({ alert: undefined, });
			setTimeout(() => { ScanbotSdkService.instance.textDataScanner?.resumeDetection() }, 500);
		} else {
			this.setState({ alert: { color: "success", text: text }, });
		}

	}

	async onError(message: string) {
		this.setState({
			alert: { color: 'error', text: message, autoClose: true },
		});
	}
}

export default withRouter(App);
