import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HashRouter, Route, Switch } from 'react-router-dom'
import { StandaloneFilteringPage } from './pages/standalone-filtering';

ReactDOM.render(
  <HashRouter>
    <React.StrictMode>
      <Switch>
        <Route path='/standalone-filtering' component={StandaloneFilteringPage} />
        <Route path='/' component={App} />
      </Switch>
    </React.StrictMode>
  </HashRouter>,
  document.getElementById('root')
);
