import { ImageUtils } from "../../utils/image-utils";

function loadImage(filename: string) {
	return ImageUtils.loadImageFromAssets('menu/' + filename);
}

export const sectionContent = ({ ...args }: any) => {
	const {
		language,
		callDocument,
		// callBarcode,
		callMrz,
		callTextData,
		viewDocuments,
		viewAcknowledgements,
		pageCount,
	} = args;

	return {
		documentScanner: {
			icon: loadImage('icon-documentScanner.svg'),
			title: language === 'de' ? 'DOKUMENTENSCANNER' : 'DOCUMENT SCANNERS',
			cards: [
				{
					image: loadImage('img-scanDocument.png'),
					title: language === 'de' ? 'Dokument scannen' : 'Scan documents',
					description:
						language === 'de'
							? 'Erfassen Sie rechteckige Dokumente, inklusive qualitätsverbessernder Funktionen'
							: 'Capture any rectangular document type, adjust with quality-enhancing features',
					onclick: callDocument,
				},
				{
					image: loadImage('img-viewDocuments.png'),
					title: language === 'de' ? 'Dokumente ansehen' : 'View documents',
					description:
						language === 'de'
							? 'Überprüfung der gescannten Dokumente'
							: 'Review the scanned documents here',
					tooltip: pageCount,
					onclick: viewDocuments,
				},
			],
		},
		dataDetectors: {
			icon: loadImage('icon-dataDetectors.svg'),
			title: language === 'de' ? 'DATENERKENNUNG' : 'DATA DETECTORS',
			cards: [
				// {
				// 	image: loadImage('img-barcode.png'),
				// 	title: language === 'de' ? 'Barcodes scannen ' : 'Scan barcodes',
				// 	description:
				// 		language === 'de'
				// 			? 'Scannen und extrahieren Sie Barcodes '
				// 			: 'Scan and extract various 1D and 2D barcodes',
				// 	onclick: callBarcode,
				// },
				{
					image: loadImage('img-scanData.png'),
					title: language === 'de' ? 'Daten scannen' : 'Scan data',
					description:
						language === 'de'
							? 'Extrahieren von benutzerdefinierten, Textdaten aus Dokumenten'
							: 'Extract user-defined text data from any document',
					onclick: callTextData,
				},
				{
					image: loadImage('image-viewScanMrz.png'),
					title: language === 'de' ? 'MRZ scannen ' : 'Scan MRZ',
					description:
						language === 'de'
							? 'Automatisches Erkennen und Auslesen von maschinenlesbaren Zonen'
							: 'Machine-readable zone will be recognized and extracted automatically',
					onclick: callMrz,
				},
			],
		},
		// identityDetectors: {
		// 	icon: loadImage('icon-identityDetectors.svg'),
		// 	title: language === 'de' ? 'IDENTITÄTSDETEKTOREN' : 'IDENTITY DETECTORS',
		// 	cards: [
		// 		{
		// 			image: loadImage('image-viewScanMrz.png'),
		// 			title: language === 'de' ? 'MRZ scannen ' : 'Scan MRZ',
		// 			description:
		// 				language === 'de'
		// 					? 'Automatisches Erkennen und Auslesen von maschinenlesbaren Zonen'
		// 					: 'Machine-readable zone will be recognized and extracted automatically',
		// 			onclick: callMrz,
		// 		},
		// 	],
		// },
		about: {
			icon: loadImage('icon-warning.svg'),
			title: language === 'de' ? 'ÜBER UNS' : 'ABOUT',
			links: [
				{
					text: language === 'de' ? 'Erfahren Sie mehr über das SDK' : 'Learn more about the SDK',
					onclick: language === 'de' ? 'https://scanbot.io/de/sdk' : 'https://scanbot.io',
				},
			],
		},
		legal: {
			icon: loadImage('icon-warning.svg'),
			title: language === 'de' ? 'RECHTLICHE HINWEISE' : 'LEGAL INFORMATION',
			links: [
				{
					text: language === 'de' ? 'Datenschutzbedingungen ' : 'Privacy policy',
					onclick: language === 'de' ? 'https://scanbot.io/de/privacy' : 'https://scanbot.io/privacy',
				},
				{
					text: language === 'de' ? 'Impressum ' : 'Imprint',
					onclick: language === 'de' ? 'https://scanbot.io/de/imprint' : 'https://scanbot.io/imprint',
				},
				{
					text: language === 'de' ? 'Danksagungen' : 'Acknowledgements',
					onclick: viewAcknowledgements,
				},
			],
		},
	};
};
