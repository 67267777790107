
import { ScanbotSdkService } from "../service/scanbot-sdk-service";
import { BarcodeResult } from "scanbot-web-sdk/@types";
import BaseScannerComponent from "./common/base-scanner-component";
import { AnimationType } from "./enum/animation-type";
import Barcodes from "../model/barcodes";
import { RoutingService } from "../service/routing-service";
import { isMobile } from 'react-device-detect';

export default class BarcodeScannerComponent extends BaseScannerComponent {
    componentDidMount() {
        this.push(AnimationType.PushRight);
    }

    render() {
        return this.controller(ScanbotSdkService.BARCODE_SCANNER_CONTAINER, "Barcode Scanner", this.labelText(), this.onCameraSwitch);
    }

    onCameraSwitch(){
        ScanbotSdkService.instance.barcodeScanner.swapCameraFacing(isMobile);
    }

    onBarcodesDetected(result: BarcodeResult) {
        this.props.onBarcodesDetected(result);
        document.getElementById("count-label")!.innerHTML = this.labelText();
    }

    labelText() {
        return Barcodes.instance.count() + " Barcodes";
    }

    onError(e: Error) {
        this.props.onError(e);
    }

    async push(type: AnimationType) {
        super.push(type);
        this.pushType = type;
        this.updateAnimationType(type, async () => {
            await ScanbotSdkService.instance.createBarcodeScanner(this.onBarcodesDetected.bind(this), this.onError.bind(this));
        });
    }

    onDonePress() {
        this.pop();
    }

    pop() {
        super.pop();
        this.updateAnimationType(AnimationType.Pop, () => {
            ScanbotSdkService.instance.disposeBarcodeScanner();
        });

        RoutingService.instance.back();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        ScanbotSdkService.instance.disposeBarcodeScanner();
    }
}
